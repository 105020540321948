import { baseRoutes } from '../../core/config/routes';

export const routes = {
  ...baseRoutes,
  admin: {
    ...baseRoutes.admin,
    employees: {
      list: '/admin/employees',
      new: '/admin/employees/new',
      edit: '/admin/employees/:id',
    },
    travelers: {
      list: '/admin/travelers',
      new: '/admin/travelers/new',
      edit: '/admin/travelers/:id',
    },
    travelerManagers: {
      new: '/admin/traveler-managers/new',
      edit: '/admin/traveler-managers/:id',
    },
    enquiries: {
      list: '/admin/enquiries',
      edit: '/admin/enquiries/:id',
    },
    quotes: {
      list: '/admin/quotes',
      edit: '/admin/quotes/:id',
    },
    airports: {
      list: '/admin/airports',
    },
    airlines: {
      list: '/admin/airlines',
    },
    suppliers: {
      list: '/admin/suppliers',
      new: '/admin/suppliers/new',
      edit: '/admin/suppliers/:id',
    },
    accommodations: {
      list: '/admin/accommodations',
      new: '/admin/accommodations/new',
      edit: '/admin/accommodations/:id',
    },
    bookings: {
      list: '/admin/bookings',
      edit: '/admin/bookings/:id',
    },
  },
  auth: {
    ...baseRoutes.auth,
  },
};
