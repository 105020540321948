import { baseApiRoutes } from '../../core/config/apiRoutes';

export const apiRoutes = {
  ...baseApiRoutes,
  employees: {
    get: 'employees/:id',
    me: 'employees/me',
    list: 'employees',
    create: 'employees',
    update: 'employees/:id',
    delete: 'employees/:id',
    rolesOptions: 'employees/role-options',
    typesOptions: 'employees/type-options',
    options: 'employees/options',
    bulkDelete: 'employees/bulk-delete',
  },
  travelers: {
    get: 'travelers/:id',
    list: 'travelers',
    create: 'travelers',
    update: 'travelers/:id',
    delete: 'travelers/:id',
    titleOptions: 'travelers/title-options',
    genderOptions: 'travelers/gender-options',
    typeOptions: 'travelers/type-options',
    wheelchairOptions: 'travelers/wheelchair-options',
    companyTravelersOptions: 'travelers/options/:companyId',
    options: 'travelers/options',
  },
  travelerInfo: {
    sourceOptions: 'traveler-info/source-options',
  },
  travelerManagers: {
    get: 'traveler-managers/:id',
    list: 'traveler-managers',
    create: 'traveler-managers',
    update: 'traveler-managers/:id',
    delete: 'traveler-managers/:id',
    options: 'traveler-managers/options/:companyId',
  },
  orders: {
    get: 'orders/:id',
    list: 'orders',
    listQuotes: 'orders/quotes',
    listBookings: 'orders/bookings',
    listTravelerQuotes: 'orders/quotes/traveler',
    listTravelerBookings: 'orders/bookings/traveler',
    create: 'orders',
    moveToQuote: 'orders/:id/move-to-quote',
    createWithQuote: 'orders/create-with-quote',
    update: 'orders/:id',
    updateAssignee: 'orders/:id/assignee',
    delete: 'orders/:id',
    archive: 'orders/:id/archive',
  },
  itineraries: {
    get: 'itineraries/:id',
    list: 'itineraries',
    create: 'itineraries',
    update: 'itineraries/:id',
    updatePrices: 'itineraries/:id/prices',
    updatePriceDisplayed: 'itineraries/:id/price-displayed',
    updatePosition: 'itineraries/position',
    duplicate: 'itineraries/:id/duplicate',
    moveToBooking: 'itineraries/:id/booking',
    delete: 'itineraries/:id',
  },
  itineraryItems: {
    get: 'itinerary-items/:id',
    list: 'itinerary-items',
    create: 'itinerary-items',
    update: 'itinerary-items/:id',
    updatePosition: 'itinerary-items/position',
    delete: 'itinerary-items/:id',
  },
  itineraryFlights: {
    updateFlightClass: 'itinerary-flights/:id/flight-class',
  },
  airports: {
    get: 'airports/:id',
    list: 'airports',
    delete: 'airports/:id',
    bulkDelete: 'airports/bulk-delete',
    options: 'airports/options',
  },
  airlines: {
    get: 'airlines/:id',
    list: 'airlines',
    create: 'airlines',
    update: 'airlines/:id',
    delete: 'airlines/:id',
    bulkDelete: 'airlines/bulk-delete',
    options: 'airlines/options',
  },
  suppliers: {
    get: 'suppliers/:id',
    list: 'suppliers',
    create: 'suppliers',
    update: 'suppliers/:id',
    delete: 'suppliers/:id',
    bulkDelete: 'suppliers/bulk-delete',
  },
  orderQuotes: {
    get: 'order-quotes/:id',
    generatePdfs: 'order-quotes/generate-pdfs',
    sendPdfs: 'order-quotes/send-pdfs',
    sendQuote: 'order-quotes/send-quote',
    createOrderQuoteTraveler: 'order-quotes/traveler',
    update: 'order-quotes/:id',
    duplicate: 'order-quotes/:id/duplicate',
    updateMainTraveler: 'order-quotes/:id/main-traveler',
    updateOrderQuoteTraveler: 'order-quotes/:id/update-traveler',
    deleteOrderQuoteTraveler: 'order-quotes/:id/delete-traveler/:traveler',
    delete: 'order-quotes/:id',
  },
  accommodations: {
    get: 'accommodations/:id',
    list: 'accommodations',
    create: 'accommodations',
    update: 'accommodations/:id',
    delete: 'accommodations/:id',
    bulkDelete: 'accommodations/bulk-delete',
    options: 'accommodations/options',
  },
  orderQuotePdfs: {
    list: 'order-quote-pdfs',
    delete: 'order-quote-pdfs/:id',
  },
  orderQuotePdfSend: {
    list: 'order-quote-pdf-send',
    get: 'order-quote-pdf-send/:id',
  },
  orderBookings: {
    get: 'order-bookings/:id',
  },
  orderQuoteNotes: {
    list: 'order-quote-notes',
    create: 'order-quote-notes',
  },
};
