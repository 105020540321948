import React from 'react';
import { routes } from '../routes/routes';
import { apiRoutes } from '../api/apiRoutes';
import BadgeIcon from '@mui/icons-material/Badge';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBox from '@mui/icons-material/AccountBox';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import Speed from '@mui/icons-material/Speed';
import EmailIcon from '@mui/icons-material/Email';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import LuggageIcon from '@mui/icons-material/Luggage';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import AirlinesIcon from '@mui/icons-material/Airlines';

export const companySidebarItems = [];

export const sidebarItems = [
  {
    title: 'SIDEBAR.GROUP_GENERAL',
    items: [
      {
        title: 'SIDEBAR.DASHBOARD',
        icon: <Speed />,
        url: routes.admin.dashboard,
      },
      {
        title: 'SIDEBAR.ENQUIRIES',
        icon: <EmailIcon />,
        url: routes.admin.enquiries.list,
      },
      {
        title: 'SIDEBAR.QUOTES',
        icon: <RequestQuoteIcon />,
        url: routes.admin.quotes.list,
      },
      {
        title: 'SIDEBAR.BOOKINGS',
        icon: <BeenhereIcon />,
        url: routes.admin.bookings.list,
      },
      {
        title: 'SIDEBAR.COMPANIES',
        icon: <BusinessIcon />,
        url: routes.admin.companies.list,
      },
      {
        title: 'SIDEBAR.TRAVELERS',
        icon: <LuggageIcon />,
        url: routes.admin.travelers.list,
      },
      {
        title: 'SIDEBAR.EMPLOYEES',
        icon: <BadgeIcon />,
        url: routes.admin.employees.list,
      },
      {
        title: 'SIDEBAR.SUPPLIERS',
        icon: <Inventory2TwoToneIcon />,
        url: routes.admin.suppliers.list,
      },
      {
        title: 'SIDEBAR.ACCOMMODATIONS',
        icon: <MapsHomeWorkIcon />,
        url: routes.admin.accommodations.list,
      },
    ],
  },
  {
    title: 'SIDEBAR.GROUP_RESOURCES',
    items: [
      {
        title: 'SIDEBAR.AIRPORTS',
        icon: <ConnectingAirportsIcon />,
        url: routes.admin.airports.list,
        apiUrl: apiRoutes.airports.list,
      },
      {
        title: 'SIDEBAR.AIRLINES',
        icon: <AirlinesIcon />,
        url: routes.admin.airlines.list,
        apiUrl: apiRoutes.airlines.list,
      },
    ],
  },
  {
    title: 'SIDEBAR.GROUP_SETTINGS',
    items: [
      {
        title: 'SIDEBAR.USERS',
        icon: <AccountBox />,
        url: routes.admin.users.list,
        apiUrl: apiRoutes.users.list,
      },
      {
        title: 'SIDEBAR.TRANSLATIONS',
        icon: <SpellcheckIcon />,
        url: routes.admin.translations.list,
        apiUrl: apiRoutes.translations.list,
      },
    ],
  },
];
